<template>
  <div class="mt-12 text-center pt-10 mx-auto" style="max-width: 700px">
          <span class="font-rubik font-weight-medium"
                style="font-size: 2rem"
          >Preguntes freqüents
          </span>
    <v-expansion-panels
      v-model="selectedFAQ"
      flat
      class="mt-8"
    >
      <v-expansion-panel
        v-for="(item,i) in items"
        :key="i"
      >
        <v-expansion-panel-header class="mt-2"
                                  :style="selectedFAQ+1 !== item ? 'border-bottom: 2px solid #E5E7EB' : ''"
        >
            <span class="font-rubik font-weight-medium" style="font-size: 1.125rem">
              {{ item.title }}
            </span>
        </v-expansion-panel-header>
        <v-expansion-panel-content class="mt-6 text-start">
            <span style="font-weight: 500; font-size: 1.125rem; color: #4B5563"
                  v-html="item.text"
            ></span>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
export default {
  name: "PreguntesFrequents",
  data() {
    return {
      selectedFAQ: null,
      items: [
        {
          title: "Quins beneficis obtinc amb ser Prèmium a Examenselectivitat?",
          text: `Amb el Prèmium d'examenselectivitat, tindràs accés a un espai d'estudi lliure de publicitat, perquè et puguis concentrar plenament en la teva preparació.
<br>
<br>
Aviat, podràs fer un seguiment dels problemes que ja hagis resolt, cosa que et permetrà portar un control del teu progrés i centrar-te en les àrees que necessitin més pràctica. També estem treballant per oferir-te la possibilitat de guardar els problemes en col·leccions personalitzades, facilitant la teva organització i revisió de continguts importants.
<br>
<br>
A més, ens comprometem a donar encara més importància al teu feedback per continuar millorant l’experiència d’ús de la web i adaptar-la a les teves necessitats d’estudi.`
        },
        {
          title: "És una subscripció?",
          text:
            "No, és un sol pagament de 4,99€."
        },
        {
          title: "Fins quan tindré el Prèmium?",
          text:
            "Un cop comprat el Prèmium, aquest estarà actiu al compte on es realitzi el pagament fins a la finalització de la selectivitat de setembre. Un cop finalitzada, el Prèmium es desactivarà automàticament."
        },
      ]
    }
  },
  computed: {
    nextYear() {
      const d1 = new Date();
      let year = d1.getFullYear();
      let month = d1.getMonth() + 1;
      if (month >= 9) year++;
      return year;
    },
  }
}
</script>

<style scoped>

</style>
